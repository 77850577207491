import React, { useState } from 'react';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pubs } from '../../../../../data';
import { Pub, User } from '../../../../../types';
import { updatePub } from '../../../../api/update-pub';
import { deletePub } from '../../api/delete-pub';
import { ButtonCell, Cell, Row } from './styled';

interface Props {
  pub: Pub;
  setPubs: (pubs: Pub[]) => void;
  users: User[];
}

export const PubRow = ({ pub, setPubs, users }: Props) => {
  const [showOwnerInput, setShowOwnerInput] = useState(false);
  const navigate = useNavigate();

  const ownerDetails = users.find((user) => user.id === pub.ownerId);

  const handleDelete = async (pubToDelete: Pub) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${pubToDelete.name}`
    );

    if (deleteConfirmed) {
      const response = await deletePub([pubToDelete.googlePlaceId]);

      if (response?.status === 200) {
        const newPubsList = pubs.filter(
          (pub) => pub.googlePlaceId !== pubToDelete.googlePlaceId
        );
        setPubs(newPubsList);
      }
    }
  };

  const handleUpdateOwner = async (ownerId: string) => {
    pub.ownerId = ownerId;
    await updatePub(pub);
  };

  const handleRowClick = () => {
    navigate(`/business/dashboard/pub/${pub.googlePlaceId}`);
  };

  return (
    <Row key={pub.googlePlaceId} onClick={handleRowClick}>
      <Cell>
        <span>{pub.name}</span>
      </Cell>
      <Cell>
        <span>{pub.vicinity}</span>
      </Cell>
      <Cell>
        {ownerDetails ? (
          <span>{ownerDetails.email}</span>
        ) : showOwnerInput ? (
          <>
            <label htmlFor={`user-id-${pub.googlePlaceId}`}>User Id: </label>
            <input
              id={`user-id-${pub.googlePlaceId}`}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                e.stopPropagation();
                handleUpdateOwner(e.target.value);
              }}
            />
          </>
        ) : (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowOwnerInput(true);
            }}
          >
            Assign Owner
          </Button>
        )}
      </Cell>
      <ButtonCell>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(pub);
          }}
        >
          Delete
        </Button>
      </ButtonCell>
    </Row>
  );
};
