import { Pub } from '../../types';
import { baseUrl } from './base-url';

export const fetchPub = async (googlePlaceId: string) => {
  try {
    const token = localStorage.getItem('token');
    const result = await fetch(`${baseUrl}/api/pub/${googlePlaceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const pub: Pub | null = await result.json();

    return pub;
  } catch (err) {
    console.log('Failed to fetch pub.', err);
    throw err;
  }
};
