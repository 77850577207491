import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { HappyHourInfo, OpeningHours } from '../../types';
import { manualUrlScrape } from '../api/manual-url-scrape';
import { CustomHeading4 } from './styled/headings';

const verifyUrl = (str: string) => {
  let url;
  try {
    url = new URL(str);
  } catch (err) {
    console.log('url', url);
    console.log('error', err);
    return false;
  }

  return ['https:', 'http:'].includes(url.protocol);
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  name: string;
  onScrape: (data: HappyHourInfo) => void;
  setLoading: (state: boolean) => void;
  setStep: (step: number) => void;
  openingHours: OpeningHours;
}

export const NewLinkStep = ({
  name,
  onScrape,
  setLoading,
  setStep,
  openingHours,
}: Props) => {
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);

  const handleScrape = async (e: React.MouseEvent) => {
    e.preventDefault();
    const validUrl = verifyUrl(url);
    if (validUrl) {
      setStep(0);
      setLoading(true);
      const happyHourInfo = await manualUrlScrape(url, openingHours);

      onScrape(happyHourInfo);
    } else {
      setError(true);
      setLoading(false);
    }
  };

  return (
    <Container>
      <CustomHeading4>{name}</CustomHeading4>
      <span>New URL: </span>
      <input
        value={url}
        onChange={(e) => {
          console.log('value', e.target.value);
          setUrl(e.target.value);
        }}
      />
      {error && <span style={{ color: 'red' }}>That's not a valid URL</span>}
      <Button
        onClick={handleScrape}
        disabled={!url}
        style={{ width: 'fit-content' }}
      >
        Scrape
      </Button>
    </Container>
  );
};
