import AppPreview from './app-preview.png';

export const appPreview = 'https://i.ibb.co/DQjbR9z/app-preview.png';
export const appStoreButton = 'https://i.ibb.co/PZptbmz/app-store.png';
export const googlePlayButton = 'https://i.ibb.co/5kGtFQw/google-play.png';
export const donnellansIcon = 'https://i.ibb.co/92rMBWq/donnellans.webp';
export const hynesIcon = 'https://i.ibb.co/6Nttxvn/hynes.png';
export const iconAndName = 'https://i.ibb.co/pdzVgbN/icon-and-name.png';
export const smithsIcon = 'https://i.ibb.co/yBD3sFL/smiths.webp';
export const theRavenIcon = 'https://i.ibb.co/bs9pNCT/the-raven.webp';
export const theShamrockIcon = 'https://i.ibb.co/Cw4YXqf/the-shamrock.webp';

export { AppPreview };
