import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../../components/styled';
import { useBusinessUser } from '../../../shared/state/user-context';
import { updateUser } from '../api/update-user';

interface Props {
  setShowUpdatePassword: (show: boolean) => void;
}

export const UpdatePassword = ({ setShowUpdatePassword }: Props) => {
  const [newPassword, setNewPassword] = useState('');
  const { email, firstName, lastName } = useBusinessUser();

  const handleUpdatePassword = async () => {
    // Handle non-200 response
    await updateUser({ email, firstName, lastName, password: newPassword });
  };

  return (
    <Container>
      {/* TODO: Require old password to be entered as well. */}
      <label htmlFor='new-password'>New Password</label>
      <StyledInput
        id='new-password'
        placeholder='New Password'
        onChange={(e) => setNewPassword(e.target.value)}
        type='password'
      />
      <Row>
        <Button onClick={() => setShowUpdatePassword(false)}>Cancel</Button>
        <Button onClick={handleUpdatePassword}>Submit</Button>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 50px;
`;

const Row = styled.div`
  display: flex;
`;

const StyledInput = styled.input`
  width: 300px;
  margin-bottom: ${spacing.lg};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  border: none;
  background-color: ${colors.paleGrey};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;
