import React, { useState } from 'react';
import styled from 'styled-components';
import { PubEvent } from '../../../../../types';
import { spacing } from '../../../../components/styled';
import { RemoveButton } from '../../../../components/styled/buttons';

export const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

interface Props {
  event: PubEvent;
  handleChange: (newValue: PubEvent) => void;
  handleRemove: () => void;
}

export const EventListItem = ({ event, handleChange, handleRemove }: Props) => {
  const [tempItem, setTempItem] = useState(event.name);
  const [tempPrice, setTempPrice] = useState(event.price);

  const handleLabelUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    // const newData = { label: event.name, price: e.target.value };
    const newData = { ...event };
    handleChange(newData);
  };

  const handlePriceUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    // const newData = { label: event.name, price: e.target.value };
    const newData = { ...event };
    handleChange(newData);
  };

  return (
    <>
      <span>{event.name}</span>
      <span>{event.description}</span>
      <span>{event.price}</span>
      <span>{event.date}</span>
      <span>{event.bookingUrl}</span>
      <span>{event.moreInfoUrl}</span>
      <StyledRemoveButton color='error' onClick={handleRemove}>
        X
      </StyledRemoveButton>
    </>
  );
};

const StyledRemoveButton = styled(RemoveButton)`
  margin: 0 !important;
  padding-top: 0;
`;

const StyledInput = styled.input`
  border-width: 1px;
  padding: ${spacing.md};
  border-radius: ${spacing.md};
`;
