import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Pub, User } from '../../../../types';
import { fetchPubs } from '../../../api/fetch-pubs';
import { fetchUsers } from '../../../api/fetch-users';
import { colors, spacing } from '../../../components/styled';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { PubsTab } from './components/pubs-tab';
import { UsersTab } from './components/users-tab';

const tabHeadings = ['Businesses', 'Users'];

export const AdminDashboard = () => {
  const [tab, setTab] = useState(0);
  // TODO: Make context
  const [users, setUsers] = useState<User[] | null>(null);
  const [pubs, setPubs] = useState<Pub[] | null>(null);

  useEffect(() => {
    const fetchPubData = async () => {
      const pubsList = await fetchPubs();
      setPubs(pubsList);
    };

    if (!pubs) {
      fetchPubData();
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const usersList = await fetchUsers();

      setUsers(usersList);
    };

    if (!users) {
      fetchUserData();
    }
  }, []);

  return (
    <SectionWrapper>
      <Container>
        {tabHeadings.map((heading, index) => {
          const active = index === tab;

          return (
            <StyledButton
              key={heading}
              $active={active}
              onClick={() => setTab(index)}
            >
              {heading}
            </StyledButton>
          );
        })}
        <TableContainer>
          {tab === 0 && pubs && users && (
            <PubsTab pubs={pubs} setPubs={setPubs} users={users} />
          )}
          {tab === 1 && users && <UsersTab users={users} setUsers={setUsers} />}
        </TableContainer>
      </Container>
    </SectionWrapper>
  );
};

const StyledButton = styled(Button)<{ $active: boolean }>`
  && {
    position: inherit;
    color: ${colors.blue};

    ${({ $active }) =>
      $active &&
      `
      border-bottom: solid 3px ${colors.blue};
      border-radius: 0;
      margin-bottom: -3px;
      font-weight: bold;
    `};
  }
`;

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  width: 100%;
  max-width: 1200px;
`;

const TableContainer = styled.div`
  margin: ${spacing.lg} 0;
`;
