import AddLocationIcon from '@mui/icons-material/AddLocation';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import StarIcon from '@mui/icons-material/Star';
import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, spacing } from '../../../components/styled';
import { SectionWrapper } from '../../shared/components/section-wrapper';

export const Benefits = () => {
  return (
    <SectionWrapper backgroundColor={colors.paleGrey}>
      <Container>
        <Benefit>
          <IconWrapper>
            <AddLocationIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h4>Over 230 Businesses</h4>
          <p>
            View listings for bars and restaurants across Vancouver, Squamish,
            Whistler and Victoria.
          </p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <MenuBookIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h4>Menus</h4>
          <p>Explore full food and drink menus for all happy hour offerings.</p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <StarIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h4>Favourties</h4>
          <p>Save your favorite spots to easily find the best deals anytime.</p>
        </Benefit>
        <Benefit>
          <IconWrapper>
            <MusicNoteIcon fontSize='large' htmlColor={colors.blue} />
          </IconWrapper>
          <h4>Events (Coming Soon)</h4>
          <p>
            See upcoming live events like trivia, music, comedy nights and more.
          </p>
        </Benefit>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  margin: ${spacing.xl} 0;

  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  grid-gap: ${spacing.lg};

  @media ${desktopBreakpoint} {
    height: 440px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 1205px) {
    height: 320px;
  }
`;

const Benefit = styled.div`
  padding: ${spacing.xl};
  background-color: white;
  border-radius: ${spacing.md};
  color: ${colors.slate};
  height: 100%;
  max-height: 170px;
  text-align: center;

  @media ${desktopBreakpoint} {
    max-height: 340px;
  }

  @media (min-width: 1205px) {
    max-height: 220px;
  }
`;

const IconWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;
