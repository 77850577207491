import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { CustomHeading4 } from './styled/headings';

interface Props {
  links: string[];
  onClick: () => void;
}

const Wrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledCustomHeading4 = styled(CustomHeading4)`
  margin-bottom: 5px;
`;

export const LinksFooter = ({ links, onClick }: Props) => {
  return (
    <Wrapper>
      <StyledCustomHeading4>Links scraped:</StyledCustomHeading4>
      {links.map((link) => (
        <a key={link} href={link}>
          {link}
        </a>
      ))}
      <Button
        style={{ width: 'fit-content', marginLeft: 0, paddingLeft: 0 }}
        size='small'
        onClick={onClick}
      >
        Use a differnt URL
      </Button>
    </Wrapper>
  );
};
