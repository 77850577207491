import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { MenuItem, Menus, Pub } from '../../types';
import { MenuList } from './menu-list';
import { AddButton } from './styled/buttons';
import { CustomHeading4 } from './styled/headings';

interface Props {
  food: Pub['food'];
  drinks: Pub['drinks'];
  onUpdate: (newMenu: Menus) => void;
  onCancel?: () => void;
}

export const Menu = ({ food, drinks, onUpdate, onCancel }: Props) => {
  const [tempFood, setTempFood] = useState(food);
  const [tempDrinks, setTempDrinks] = useState(drinks);

  const handleUpdate = (newItems: MenuItem[], foodUpdate?: boolean) => {
    foodUpdate ? setTempFood(newItems) : setTempDrinks(newItems);
  };

  const handleSave = () => {
    onUpdate({ food: tempFood, drinks: tempDrinks });
  };

  const handleAdd = (newFood: boolean) => {
    if (newFood) {
      handleUpdate([...food, { label: '', price: '' }], true);
    } else {
      handleUpdate([...drinks, { label: '', price: '' }], false);
    }
  };

  return (
    <Container>
      <CustomHeading4>Drinks</CustomHeading4>
      <MenuWrapper>
        <MenuList
          items={tempDrinks}
          onUpdate={(newDrinks) => handleUpdate(newDrinks)}
        />
        <AddButton onClick={() => handleAdd(false)}>Add</AddButton>
      </MenuWrapper>
      <CustomHeading4>Food</CustomHeading4>
      <MenuWrapper>
        <MenuList
          items={tempFood}
          onUpdate={(newFood) => handleUpdate(newFood, true)}
        />
        <AddButton onClick={() => handleAdd(true)}>Add</AddButton>
      </MenuWrapper>
      <HorizontalWrapper>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        <Button onClick={handleSave}>Save</Button>
      </HorizontalWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
