import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { desktopBreakpoint } from '../../../components/styled';

interface Props {
  backgroundColor?: string;
  transitionBackground?: boolean;
  mobileColumns?: boolean;
  maxWidth?: string;
  isSticky?: boolean;
}

export const SectionWrapper = ({
  backgroundColor,
  transitionBackground = false,
  mobileColumns = false,
  maxWidth,
  isSticky = false,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <FullWidthContainer
      $backgroundColor={backgroundColor}
      $isSticky={isSticky}
      $transitionBackground={transitionBackground}
    >
      <StyledSection $mobileColumns={mobileColumns} $maxWidth={maxWidth}>
        {children}
      </StyledSection>
    </FullWidthContainer>
  );
};

const FullWidthContainer = styled.div<{
  $backgroundColor?: string;
  $isSticky: boolean;
  $transitionBackground: boolean;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  ${({ $transitionBackground }) =>
    $transitionBackground && `transition: background-color 1000ms linear`};
  width: 100%;
  ${({ $isSticky }) =>
    $isSticky
      ? `
      position: sticky; 
      top: 0px;
    `
      : ''}
`;

const StyledSection = styled.div<{
  $mobileColumns: boolean;
  $maxWidth?: string;
}>`
  width: 90%;
  max-width: ${({ $maxWidth }) => $maxWidth ?? '1200px'};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;

  @media ${desktopBreakpoint} {
    width: 80%;
    flex-direction: row;
  }
`;
