import React from 'react';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { LegalTextContainer } from '../../shared/components/styled';

export const TermsAndConditions = () => {
  return (
    <SectionWrapper>
      <LegalTextContainer>
        <h1>Terms and Conditions</h1>
        <h5>Effective 21 October 2024</h5>
        <h2>Disclaimer</h2>
        <p>
          We strive to keep the information provided in the app as accurate as
          possible. However, we make no guarantees that the information is fully
          up-to-date as businesses regularly change their happy hour times and
          menu. We will assume no responsibility for any inaccuracies within the
          app or with any websites linked to from the app.
        </p>
      </LegalTextContainer>
    </SectionWrapper>
  );
};
