import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  element: React.ComponentType;
  requireAdmin?: boolean;
}

export const ProtectedRoute = ({ element: Component, requireAdmin }: Props) => {
  const isAdmin = () => {
    const token = localStorage.getItem('token');
    try {
      if (token) {
        const decodedToken: { user: { role: string } } = jwtDecode(token);

        return requireAdmin ? decodedToken?.user.role === 'admin' : !!token;
      }
    } catch (err) {
      console.error('Failed to decode token', err);
      return false;
    }
  };

  return isAdmin() ? <Component /> : <Navigate to='/business/login' replace />;
};
