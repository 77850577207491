import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

interface ContextType {
  token: string | null;
  setToken: (token: string) => void;
}

const defaultContext = {
  token: null,
  setToken: () => {},
};

const TokenContext = createContext<ContextType>(defaultContext);

export const useTokenContext = () => useContext(TokenContext);

export const TokenProvider = ({ children }: PropsWithChildren<{}>) => {
  const [token, setToken] = useState<string | null>(null);

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      {children}
    </TokenContext.Provider>
  );
};
