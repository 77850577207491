import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pub } from '../../../../../types';
import { baseUrl } from '../../../../api/base-url';
import { useTokenContext } from '../../../shared/state/token-context';
import { useBusinessUser } from '../../../shared/state/user-context';

interface ResponseBody {
  accessToken: string;
  firstName: string;
  lastName: string;
  email: string;
  pubs: Pub[];
}

export const useBusinessLogin = () => {
  const [error, setError] = useState(false);
  const { setToken } = useTokenContext();
  const navigate = useNavigate();
  const { setFirstName, setLastName, setEmail, setPubs } = useBusinessUser();

  const login = async (
    user: { email: string; password: string },
    turnstileToken: string
  ) => {
    try {
      const response = await fetch(`${baseUrl}/api/user/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...user, turnstileToken }),
      });

      if (response.status !== 200) {
        setError(true);
      } else {
        const { accessToken, firstName, lastName, email, pubs }: ResponseBody =
          await response.json();
        setToken(accessToken);
        localStorage.setItem('token', accessToken);
        setFirstName(firstName);
        setLastName(lastName);
        setEmail(email);
        setPubs(pubs);
        navigate('/business/dashboard');
        setError(false);
      }
    } catch (err) {
      setError(true);
      console.log('Error logging in');
    }
  };

  return { error, login };
};
