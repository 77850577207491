import { Button } from '@mui/material';
import React from 'react';
import { IoChevronBackOutline } from 'react-icons/io5';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
}

const StyledButton = styled(Button)`
  margin-left: 0;
  padding-left: 0 !important;
  min-width: 10px !important;
`;

const StyledIoChevronBackOutline = styled(IoChevronBackOutline)`
  vertical-align: text-bottom;
  margin-top: 2px;
`;

export const BackButton = ({ onClick }: Props) => {
  return (
    <StyledButton onClick={onClick}>
      <div>
        <StyledIoChevronBackOutline size={20} />
      </div>
    </StyledButton>
  );
};
