import { Button } from '@mui/material';
import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { HappyHourInfo, HappyHoursByDay, Pub } from '../../types';
import { fetchPub } from '../api/fetch-pub';
import { generateMenu } from '../api/generate-menu';
import { updatePub } from '../api/update-pub';
import {
  existingPubStep,
  hoursStep,
  manualLinkStep,
  menuStep,
  successStep,
  summaryStep,
} from '../helpers/form-steps';
import { BackButton } from './back-button';
import { ExistingStep } from './existing-step';
import { HappyHours } from './happy-hours';
import { LinksFooter } from './links-footer';
import { Loader } from './loader';
import { Menu } from './menu';
import { NewLinkStep } from './new-link-step';
import { formFieldReducer } from './state/form-field-reducer';
import { Step1 } from './step-1';
import { colors } from './styled/colors';
import { Success } from './success';
import { Summary } from './summary';

const defaultState: Pub = {
  googlePlaceId: '',
  name: '',
  website: '',
  latitude: 0,
  longitude: 0,
  happyHours: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] },
  drinks: [],
  food: [],
  links: [],
  openingHours: [],
  vicinity: '',
  file: undefined,
};

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  padding-bottom: 10px;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
`;

const StyledFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomHeading3 = styled.h3`
  margin: 0;
  color: ${colors.blue};
`;

const Container = styled.div`
  margin: 20px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const headings: Record<number, string> = {
  1: 'Search',
  [existingPubStep]: 'Existing Pub',
  [hoursStep]: 'Hours',
  [menuStep]: 'Menus',
  [summaryStep]: 'Summary',
  [successStep]: 'Success',
  [manualLinkStep]: 'Manual Link',
};

export const NewPubForm = () => {
  const [state, dispatch] = useReducer(formFieldReducer, defaultState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [step, setStep] = useState(1);
  const [backStep, setBackStep] = useState(0);
  const [existingPub, setExistingPub] = useState(false);
  const { links, openingHours, file, text } = state;
  const showLinks =
    !!links.length &&
    step !== 1 &&
    step !== successStep &&
    step !== manualLinkStep &&
    !loading;
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(
    null
  );
  const nextDisabled =
    step === 1 && (!place || (links.length === 0 && !file && !text));
  const resetActive = !!file || !!text || links.length > 0;

  const fetchPlaceData = async (refreshData = false) => {
    setLoading(true);
    setStep(0);

    if (place) {
      const {
        name,
        vicinity,
        website,
        opening_hours: openingHours,
        place_id: googlePlaceId,
      } = place;
      dispatch({ type: 'addPlaceData', data: { place } });

      if (!name || !website || !vicinity || !openingHours || !googlePlaceId) {
        console.log(
          `onPlaceSelect method: missing value, googlePlaceId: ${googlePlaceId}, name: ${name}, vicinity: ${vicinity}, website: ${website}, openingHours: ${openingHours}`
        );
        setError(
          `Missing Google Place info, googlePlaceId: ${googlePlaceId}, name: ${name}, vicinity: ${vicinity}, website: ${website}, openingHours: ${openingHours}`
        );

        setLoading(false);
        setStep(1);
        return;
      }
      setError('');

      let happyHourInfo: HappyHourInfo;
      const pubData = await fetchPub(googlePlaceId);

      console.log('pubData', pubData);

      try {
        if (pubData && !refreshData) {
          console.log(pubData);
          const { drinks, food, happyHours } = pubData;
          happyHourInfo = { drinks, food, happyHours, links: [] };
          setExistingPub(true);
          setStep(existingPubStep);
        } else {
          happyHourInfo = await generateMenu({
            name,
            website,
            vicinity,
            openingHours,
            links,
            file,
            text,
          });
          setStep(hoursStep);
        }

        const {
          drinks,
          food,
          happyHours,
          links: returnedLinks,
        } = happyHourInfo;
        dispatch({ type: 'addLinks', data: returnedLinks });
        dispatch({ type: 'updateMenus', data: { drinks, food } });
        dispatch({ type: 'updateHappyHours', data: happyHours });
      } catch (err) {
        setError('Error generating menu from URL.');
        setStep(1);
      }
      setLoading(false);
    }
  };

  const handleNewLinkScrape = ({
    drinks,
    food,
    happyHours,
    links: returnedLinks,
  }: HappyHourInfo) => {
    dispatch({ type: 'updateMenus', data: { drinks, food } });
    dispatch({ type: 'updateHappyHours', data: happyHours });
    dispatch({ type: 'addLinks', data: returnedLinks });
    setLoading(false);
    setStep(hoursStep);
    setBackStep(0);
  };

  const onUpdateHappyHours = (data: HappyHoursByDay) => {
    dispatch({ type: 'updateHappyHours', data });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit state', state);
    e.preventDefault();
    const update = async () => {
      await updatePub(state);
    };

    update();
    setStep(successStep);
  };

  const handleNewLinkClick = () => {
    setBackStep(step);
    setStep(manualLinkStep);
  };

  const handleBackClick = () => {
    if (step === manualLinkStep) {
      setStep(backStep);
      setBackStep(0);
    } else if (step === hoursStep && !existingPub) {
      setStep(1);
    } else {
      setStep((prev) => prev - 1);
    }
  };

  const handleNextClick = () => {
    if (step === 1) {
      fetchPlaceData();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const resetForm = () => {
    setStep(1);
    setPlace(null);
    setExistingPub(false);
    dispatch({ type: 'addLinks', data: [] });
    dispatch({ type: 'uploadFile' });
    dispatch({ type: 'addText' });
  };

  console.log('place', place);
  console.log('state', state);

  return (
    <Container>
      <StyledHeader>
        {step !== 1 && step !== successStep && !loading && (
          <BackButton onClick={handleBackClick} />
        )}
        <CustomHeading3>{headings[step]}</CustomHeading3>
      </StyledHeader>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <StyledContent data-testid='styled-content'>
          {loading && <Loader />}

          {step === 1 && (
            <Step1 onPlaceSelect={setPlace} state={state} dispatch={dispatch} />
          )}
          {step === existingPubStep && (
            <ExistingStep pub={state} setStep={setStep} />
          )}
          {step === hoursStep && (
            <HappyHours
              times={state.happyHours}
              openingHours={state.openingHours}
              onUpdate={(data: HappyHoursByDay) => {
                onUpdateHappyHours(data);
                handleNextClick();
              }}
            />
          )}
          {step === menuStep && (
            <Menu
              drinks={state.drinks}
              food={state.food}
              onUpdate={(newMenus) =>
                dispatch({ type: 'updateMenus', data: newMenus })
              }
            />
          )}
          {step === summaryStep && <Summary pub={state} />}
        </StyledContent>
        {step === summaryStep && (
          <StyledFooter>
            <Button type='submit' style={{ marginTop: 5 }}>
              Submit
            </Button>
          </StyledFooter>
        )}
        {step == successStep && (
          <Success name={state.name} resetForm={resetForm} />
        )}
        {step === manualLinkStep && (
          <NewLinkStep
            name={state.name}
            onScrape={handleNewLinkScrape}
            setLoading={setLoading}
            setStep={setStep}
            openingHours={openingHours}
          />
        )}
      </form>
      {error && <span style={{ color: 'red' }}>{error}</span>}
      <StyledFooter>
        <StyledRow>
          {step === 1 && (
            <Button onClick={resetForm} disabled={!resetActive}>
              Reset
            </Button>
          )}
          {[1, menuStep].includes(step) && (
            <Button
              onClick={handleNextClick}
              disabled={nextDisabled}
              style={{ marginLeft: 'auto' }}
            >
              Next
            </Button>
          )}
        </StyledRow>
        {showLinks && (
          <LinksFooter links={links} onClick={handleNewLinkClick} />
        )}
      </StyledFooter>
    </Container>
  );
};
