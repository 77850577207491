import React from 'react';
import styled from 'styled-components';
import { StandardWidthButton } from './styled/buttons';

interface Props {
  name: string;
  resetForm: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Success = ({ name, resetForm }: Props) => {
  return (
    <Container>
      <span
        style={{ marginBottom: '10px' }}
      >{`Happy hour info for ${name} has been added successfully.`}</span>
      <StandardWidthButton onClick={resetForm}>Add Another</StandardWidthButton>
    </Container>
  );
};
