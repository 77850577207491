import React from 'react';
import styled from 'styled-components';
import { colors, desktopBreakpoint, spacing } from '../../../components/styled';
import {
  AppPreview,
  appStoreButton,
  googlePlayButton,
} from '../../../public/images';
import { SectionWrapper } from '../../shared/components/section-wrapper';

export const Cover = () => {
  return (
    <SectionWrapper backgroundColor={colors.blue}>
      <GridContainer>
        <TextContainer>
          <h1>Find happy hours and live events.</h1>
          <StyledParagraph>
            Discover great offers as well as live music, trivia nights, comedy
            shows and more at the best bars and restaurants near you.
          </StyledParagraph>
          <ButtonsContainer>
            <a href='https://apps.apple.com/ca/app/vibe-happy-hours-and-more/id6705122593'>
              <AppStoreButton src={appStoreButton} />
            </a>
            <a href='https://play.google.com/store/apps/details?id=com.burnspbrian.happyhour'>
              <GooglePlayButton src={googlePlayButton} />
            </a>
          </ButtonsContainer>
        </TextContainer>
        <ImageContainer>
          <Image src={AppPreview} />
        </ImageContainer>
      </GridContainer>
    </SectionWrapper>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${desktopBreakpoint} {
    grid-template-columns: 0.8fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const TextContainer = styled.div`
  margin-bottom: ${spacing.lg};
  align-self: end;
  color: white;
  text-align: center;
  grid-column: 1;
  grid-row: 2;
  padding-bottom: ${spacing.xl};

  @media ${desktopBreakpoint} {
    text-align: left;
    grid-column: 1;
    grid-row: 1;
    padding: ${spacing.xl};
    padding-right: 0;
  }
`;

const ImageContainer = styled.div`
  padding: ${spacing.lg};
  padding-bottom: 0;
  grid-column: 1;
  grid-row: 1;
  display: flex;

  @media ${desktopBreakpoint} {
    padding: ${spacing.lg};
    grid-column: 2;
    grid-row: 1;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 80%;
  aspect-ratio: 1 / 1;
  margin-left: auto;
  margin-right: auto;

  @media ${desktopBreakpoint} {
    width: 100%;
    max-width: 500px;
  }
`;

const StyledParagraph = styled.p`
  font-weight: 500;

  @media ${desktopBreakpoint} {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  margin-top: ${spacing.xl};
`;

const AppStoreButton = styled.img`
  margin-right: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  max-height: 45px;
`;

const GooglePlayButton = styled.img`
  margin-left: ${spacing.md};
  object-fit: cover;
  border-radius: 8px;
  max-height: 45px;
`;
