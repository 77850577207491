import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { PubEvent } from '../../../../../types';
import { AddButton } from '../../../../components/styled/buttons';

interface Props {
  events?: PubEvent[];
  onUpdate: (newEvents: PubEvent[]) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const EventsInputs = ({ events, onUpdate }: Props) => {
  const [tempEvents, setTempEvents] = useState(events);

  const handleUpdate = (newItems: PubEvent[], foodUpdate?: boolean) => {
    console.log('newEvents', newItems);

    // if (foodUpdate) {
    //   const newMenus: Menus = { drinks, food: newItems };
    //   onUpdate(newMenus);
    // } else {
    //   const newMenus: Menus = { food, drinks: newItems };
    //   onUpdate(newMenus);
    // }
  };

  const handleAdd = (newFood: boolean) => {
    // TODO: Create new empty event but only fire request on save
    // if (newFood) {
    //   handleUpdate([...food, { label: '', price: '' }], true);
    // } else {
    //   handleUpdate([...drinks, { label: '', price: '' }], false);
    // }
  };

  return (
    <Container>
      {events &&
        events.map((event, index) => {
          return (
            <>
              <span>{event.name}</span>
              <span>{event.description}</span>
              <span>{event.price}</span>
              <span>{event.date}</span>
              <span>{event.bookingUrl}</span>
              <span>{event.moreInfoUrl}</span>
            </>
          );
        })}
      <AddButton onClick={() => handleAdd(false)}>Add</AddButton>
      <Button onClick={() => onUpdate(events ?? [])}>Save</Button>
    </Container>
  );
};
