import { Button, Popper, PopperProps } from '@mui/material';
import { PopperChildrenProps } from '@mui/material/Popper/BasePopper.types';
import React, { useState } from 'react';
import styled from 'styled-components';

export const CustomPopper = (
  props: PopperProps & { text?: string; handleCustomTextClick: () => void }
) => {
  const { children, text, handleCustomTextClick, ...rest } = props;

  return (
    <Popper {...rest}>
      {(popperChildrenProps) => (
        <CustomChildren
          {...popperChildrenProps}
          text={text}
          handleCustomTextClick={handleCustomTextClick}
          popperChildren={children}
        />
      )}
    </Popper>
  );
};

const CustomChildren = (
  popperChildrenProps: PopperChildrenProps & {
    text?: string;
    handleCustomTextClick: () => void;
    popperChildren:
      | React.ReactNode
      | ((props: PopperChildrenProps) => React.ReactNode);
  }
) => {
  const [show, setShow] = useState(false);
  const { popperChildren, TransitionProps, handleCustomTextClick, text } =
    popperChildrenProps;

  const customEnter = () => {
    setShow(true);
    TransitionProps?.onEnter();
  };

  const customExit = () => {
    setShow(false);
    TransitionProps?.onExited();
  };

  // Hack
  if (typeof popperChildren === 'function') {
    const ReturnChildren = popperChildren({
      ...popperChildrenProps,
      TransitionProps: {
        in: TransitionProps?.in as boolean,
        onEnter: customEnter,
        onExited: customExit,
      },
    });

    return (
      <>
        {show && (
          <div
            style={{
              width: '100%',
              backgroundColor: 'white',
              boxShadow:
                '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            }}
          >
            <StyledButton size='large' onClick={handleCustomTextClick}>
              {text}
            </StyledButton>
          </div>
        )}
        {ReturnChildren}
      </>
    );
  }
};

const StyledButton = styled(Button)`
  width: 100%;
`;
