import { HappyHoursByDay, Menus, Pub } from '../../../types';

interface AddPlaceData {
  type: 'addPlaceData';
  data: { place: google.maps.places.PlaceResult };
}

interface UpdateHappyHours {
  type: 'updateHappyHours';
  data: HappyHoursByDay;
}

interface UpdateMenus {
  type: 'updateMenus';
  data: Menus;
}

interface AddLinks {
  type: 'addLinks';
  data: string[];
}

interface UploadFile {
  type: 'uploadFile';
  data?: File;
}

interface AddText {
  type: 'addText';
  data?: string;
}

export type Action =
  | AddPlaceData
  | UpdateHappyHours
  | UpdateMenus
  | AddLinks
  | UploadFile
  | AddText;
type State = Pub;

export const formFieldReducer = (state: State, action: Action) => {
  if (action.type === 'addPlaceData') {
    const place = action.data.place;

    const {
      name,
      place_id: googlePlaceId,
      website,
      geometry,
      opening_hours: openingHours,
      vicinity,
    } = place;

    if (geometry) {
      const { location } = geometry;
      if (location) {
        const { lat, lng } = location;
        const latitude = lat();
        const longitude = lng();

        return {
          ...state,
          name,
          googlePlaceId,
          website,
          latitude,
          longitude,
          openingHours: openingHours?.periods,
          vicinity,
        } as State;
      }
    }

    return {
      ...state,
      name,
      googlePlaceId,
      website,
      openingHours: openingHours?.periods,
      vicinity,
    } as State;
  }

  if (action.type === 'updateHappyHours') {
    const newState: State = { ...state, happyHours: action.data };
    return newState;
  }

  if (action.type === 'updateMenus') {
    const newState: State = {
      ...state,
      drinks: action.data.drinks,
      food: action.data.food,
    };
    return newState;
  }

  if (action.type === 'addLinks') {
    const newState: State = { ...state, links: action.data };
    return newState;
  }

  if (action.type === 'uploadFile') {
    const newState: State = { ...state, file: action.data };
    return newState;
  }

  if (action.type === 'addText') {
    const newState: State = { ...state, text: action.data };
    return newState;
  }

  return { ...state };
};
