import React from 'react';
import { colors } from '../../../components/styled';

export const SuccessMessage = () => (
  <>
    <h1>Thank You!</h1>
    <div style={{ color: colors.slate }}>
      <p>
        Thanks for helping make Vibe even better! Your input improves the
        experience for everyone!
      </p>
      <p>
        We'll do our best to have your suggestion listed in the next 24 hours!
      </p>
    </div>
  </>
);
