import { HappyHourInfo, OpeningHours } from '../../types';
import { baseUrl } from './base-url';

export const manualUrlScrape = async (
  url: string,
  openingHours: OpeningHours
) => {
  try {
    const result = await fetch(`${baseUrl}/api/manual-url-scrape`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url, openingHours }),
    });

    const menusAndHappyHours: HappyHourInfo = await result.json();

    console.log('menus', menusAndHappyHours);

    return menusAndHappyHours;
  } catch (err) {
    console.log('Failed to generate menu from provided url', err);
    throw err;
  }
};
