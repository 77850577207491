import { Pub } from '../../types';
import { baseUrl } from './base-url';

export const updatePub = async (pub: Pub) => {
  try {
    const token = localStorage.getItem('token');
    await fetch(`${baseUrl}/api/pub`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...pub }),
    });
  } catch (err) {
    console.log('Failed to create or update pub.', err);
  }
};
