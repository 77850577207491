import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  fontWeight,
  spacing,
} from '../../../components/styled';
import { iconAndName } from '../../../public/images';
import { SectionWrapper } from '../../shared/components/section-wrapper';

export const Header = () => {
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () =>
      setScrolledDown(window.scrollY > 0)
    );
  });

  return (
    <SectionWrapper
      backgroundColor={scrolledDown ? colors.slate : colors.blue}
      transitionBackground={scrolledDown}
      maxWidth='1800px'
      isSticky={true}
    >
      <ContentWrapper>
        <LogoContainer>
          <Link to='/'>
            <Logo src={iconAndName} />
          </Link>
        </LogoContainer>
        <Nav>
          {/* <NavItem>
            <Link to='/'>Home</Link>
          </NavItem> */}
          {/* TODO */}
          {/* <NavItem>
            <Link to='business/login'>Login</Link>
          </NavItem>
          <NavItem>
            <Link to='business/register'>Register</Link>
          </NavItem> */}
        </Nav>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.md};
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div``;

const Logo = styled.img`
  max-width: 120px;

  @media ${desktopBreakpoint} {
    max-width: 180px;
  }
`;

const Nav = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NavItem = styled.li`
  display: inline;
  margin-right: ${spacing.md};
  padding: ${spacing.md};

  a {
    text-decoration: none;
    color: white;
    font-size: 20px;
    font-weight: ${fontWeight.medium};
  }
`;
