import { Button } from '@mui/material';
import * as React from 'react';
import { User } from '../../../../../types';
import { deleteUser } from '../../api/delete-user';
import { ButtonCell, Cell, HeadingRow, Row, StyledTable } from './styled';

interface Props {
  users: User[];
  setUsers: (users: User[]) => void;
}

export const UsersTab = ({ users, setUsers }: Props) => {
  const handleDelete = async (userToDelete: User) => {
    const deleteConfirmed = confirm(
      `Are you sure you want to delete: \n\n${userToDelete.firstName} ${userToDelete.lastName}`
    );

    if (deleteConfirmed) {
      const response = await deleteUser(userToDelete.id);

      if (response?.status === 200) {
        const newUsersList = users.filter(
          (user) => user.id !== userToDelete.id
        );
        setUsers(newUsersList);
      }
    }
  };

  return (
    <>
      <StyledTable>
        <HeadingRow>
          <Cell>
            <span>Name</span>
          </Cell>
          <Cell>
            <span>Email</span>
          </Cell>
          <ButtonCell>
            <span>Role</span>
          </ButtonCell>
          <Cell>
            <span>ID</span>
          </Cell>
          <ButtonCell></ButtonCell>
          <ButtonCell></ButtonCell>
        </HeadingRow>
        {users.map((user) => {
          return (
            <Row key={user.id}>
              <Cell>
                <span>{`${user.firstName} ${user.lastName}`}</span>
              </Cell>
              <Cell>
                <span>{user.email}</span>
              </Cell>
              <ButtonCell>
                <span>{user.role}</span>
              </ButtonCell>
              <Cell>
                <span>{user.id}</span>
              </Cell>
              <ButtonCell>
                <Button>Update</Button>
              </ButtonCell>
              <ButtonCell>
                <Button onClick={() => handleDelete(user)}>Delete</Button>
              </ButtonCell>
            </Row>
          );
        })}
      </StyledTable>
    </>
  );
};
