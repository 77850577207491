import React from 'react';
import styled from 'styled-components';
import { Pub } from '../../types';
import { EventsList } from '../features/business/dashboard/components/events-list';
import { displayDay } from '../helpers/date';
import { CustomHeading3 } from './styled/headings';

interface Props {
  pub: Pub;
}

export const Summary = ({ pub }: Props) => {
  const { happyHours, food, drinks, events } = pub;
  const showEvents = events && events.length > 0;

  return (
    <Container>
      <CustomHeading3>Happy Hour Times</CustomHeading3>
      <Content>
        {Object.entries(happyHours).map(([day, happyHours]) => {
          return (
            <DayWrapper key={day}>
              <Day>{`${displayDay(day)}:`}</Day>
              <TimesWrapper>
                {happyHours.map((times) => (
                  <span key={times.startTime}>
                    {times.startTime} - {times.endTime}
                  </span>
                ))}
                {!happyHours.length && <span>-</span>}
              </TimesWrapper>
            </DayWrapper>
          );
        })}
      </Content>
      {!!drinks.length && (
        <>
          <CustomHeading3>Drinks</CustomHeading3>
          <Content>
            {drinks.map(({ label, price }) => (
              <DayWrapper key={label}>
                <Day>{`${label}:`}</Day>
                <span>{`$${price}`}</span>
              </DayWrapper>
            ))}
          </Content>
        </>
      )}
      {!!food.length && (
        <>
          <CustomHeading3>Food</CustomHeading3>
          <Content>
            {food.map(({ label, price }) => (
              <DayWrapper key={label}>
                <Day>{`${label}:`}</Day>
                <span>{`$${price}`}</span>
              </DayWrapper>
            ))}
          </Content>
        </>
      )}
      {showEvents && (
        <>
          <CustomHeading3>Events</CustomHeading3>
          <Content>
            <EventsList events={events} onUpdate={() => {}} />
          </Content>
        </>
      )}
    </Container>
  );
};

const DayWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const Day = styled.span`
  flex-basis: 65%;
`;

const TimesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 500px;
`;

const Content = styled.div`
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
`;
