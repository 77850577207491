import { Button } from '@mui/material';
import styled from 'styled-components';

export const RemoveButton = styled(Button)`
  width: fit-content !important;
  min-width: 10px !important;
  margin-top: 15px !important;
`;

export const AddButton = styled(Button)`
  margin-left: 0px !important;
`;

export const StandardWidthButton = styled(Button)`
  width: fit-content;
`;
