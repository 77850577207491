import { User } from '../../../../../types';
import { baseUrl } from '../../../../api/base-url';

export const updateUser = async (user: Omit<User, 'role' | 'id'>) => {
  try {
    const token = localStorage.getItem('token');
    await fetch(`${baseUrl}/api/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(user),
    });
  } catch (err) {
    console.log('Failed to update user data.', err);
  }
};
