import { HappyHoursByDay, Pub } from './types';

const dummyHappyHours: HappyHoursByDay = {
  0: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '24:00' },
  ],
  1: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '24:00' },
  ],
  2: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '24:00' },
  ],
  3: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '24:00' },
  ],
  4: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '24:00' },
  ],
  5: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '24:00' },
  ],
  6: [
    { startTime: '14:00', endTime: '18:00' },
    { startTime: '21:00', endTime: '1:00' },
  ],
};

const dummyDrinks = {
  Highball: '2.00',
  Jameson: '4.00',
};
const dummyFood = {
  Fries: '2.00',
  'Burger and a beer': '4.00',
};

const defaultOpeningTimes = {
  open: { day: 0, time: '', hours: 0, minutes: 0 },
  close: { day: 0, time: '', hours: 0, minutes: 0 },
};

export const pubs: Pub[] = [
  {
    googlePlaceId: 'ChIJ57qrv7dzhlQRRuI062Hll2o',
    name: `Carlos O'Bryan's`,
    website: 'https://www.kobcob.com/happy-hour/',
    latitude: 49.265325804046235,
    longitude: -123.14535265106639,
    happyHours: {
      1: [{ startTime: '14:00', endTime: '17:00' }],
      2: [{ startTime: '14:00', endTime: '17:00' }],
      3: [{ startTime: '14:00', endTime: '17:00' }],
      4: [{ startTime: '14:00', endTime: '17:00' }],
      5: [{ startTime: '14:00', endTime: '17:00' }],
      6: [{ startTime: '14:00', endTime: '17:00' }],
      0: [{ startTime: '14:00', endTime: '17:00' }],
    },
    drinks: [],
    food: [],
    links: [],
    openingHours: [],
    vicinity: 'Fairview',
    file: {} as File,
  },
  {
    googlePlaceId: 'ChIJi5E3G7ZzhlQR_PzPyIqgfBI',
    name: `Romer's`,
    website: 'https://romersburgerbar.com/menus/drink-deals-happy-hour/',
    latitude: 49.268299099229296,
    longitude: -123.14719794566595,
    happyHours: {
      1: [{ startTime: '14:00', endTime: '17:00' }],
      2: [{ startTime: '14:00', endTime: '17:00' }],
      3: [{ startTime: '14:00', endTime: '17:00' }],
      4: [{ startTime: '14:00', endTime: '17:00' }],
      5: [{ startTime: '14:00', endTime: '17:00' }],
      6: [{ startTime: '14:00', endTime: '17:00' }],
      0: [{ startTime: '14:00', endTime: '17:00' }],
    },
    drinks: [],
    food: [],
    links: [],
    openingHours: [],
    vicinity: 'Kitsilano',
    file: {} as File,
  },
];
