import React from 'react';
import styled from 'styled-components';
import { PubEvent } from '../../../../../types';
import { EventListItem } from './event-list-item';

interface Props {
  events: PubEvent[];
  onUpdate: (newItems: PubEvent[]) => void;
}

export const EventsList = ({ events, onUpdate }: Props) => {
  const handleChange = (newValue: PubEvent, index: number) => {
    const newItems = [...events];
    newItems[index] = newValue;

    onUpdate(newItems);
  };

  const handleRemove = (index: number) => {
    const newItems = [...events];
    newItems.splice(index, 1);
    onUpdate(newItems);
  };

  return (
    <>
      {events.map((event, index) => {
        return (
          <EventListItem
            event={event}
            // handleChange={(newValue) => handleChange(newValue, index)}
            handleChange={(newValue) => console.log(newValue)}
            handleRemove={() => handleRemove(index)}
            key={event.id}
          />
        );
      })}
    </>
  );
};

export const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
