import { Button, TextareaAutosize } from '@mui/material';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IoMdCloudUpload } from 'react-icons/io';
import styled from 'styled-components';
import { PlaceType, Pub } from '../../types';
import { Action } from './state/form-field-reducer';
import { CustomHeading4 } from './styled/headings';

const StyleInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
`;

const StyledSpan = styled.span`
  margin-bottom: 10px;
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  resize: vertical;
  width: 100%;
  min-height: 40px;
  margin-bottom: 10px;
`;

const VisuallyHiddenInput = styled.input({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
  state: Pub;
  dispatch: React.Dispatch<Action>;
}

export const Step1 = ({ onPlaceSelect, state, dispatch }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [place, setPlace] = useState<PlaceType | null>(null);
  const placesLib = useMapsLibrary('places');
  const { links, text, file } = state;
  const hideUrlInputDisabled = !!text || !!file;
  const hideTextInput = links.length !== 0 || !!file;
  const hideFileUpload = links.length !== 0 || !!text;

  useEffect(() => {
    if (!placesLib || !inputRef.current) return;

    const options: google.maps.places.AutocompleteOptions = {
      types: ['establishment'],
      componentRestrictions: { country: 'can' },
      fields: [
        'geometry',
        'name',
        'place_id',
        'website',
        'opening_hours',
        'vicinity',
      ],
    };

    setPlace(new placesLib.Autocomplete(inputRef.current, options));
  }, [placesLib]);

  useEffect(() => {
    if (!place) return;

    place.addListener('place_changed', () => {
      onPlaceSelect(place.getPlace());
    });
  }, [onPlaceSelect, place]);

  const handleAddUrl = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const newLinks = links;
    newLinks[index] = e.target.value;
    dispatch({ type: 'addLinks', data: newLinks });
    dispatch({ type: 'uploadFile' });
    dispatch({ type: 'addText' });
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    console.log(file);
    if (file) {
      dispatch({ type: 'uploadFile', data: file });
      dispatch({ type: 'addLinks', data: [] });
      dispatch({ type: 'addText' });
    }
  };

  const handleTextInput = (
    e: React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    dispatch({ type: 'addText', data: e.target.value });
    dispatch({ type: 'uploadFile' });
    dispatch({ type: 'addLinks', data: [] });
  };

  return (
    <>
      <StyleInput
        ref={inputRef}
        style={{ width: '100%' }}
        placeholder='Search for a pub or restaurant'
      />
      <CustomHeading4 style={{ width: '100%', marginBottom: '5px' }}>
        Menu
      </CustomHeading4>
      <StyledSpan>
        Paste a URL, the text of the menu or upload a PDF. Only one required.
      </StyledSpan>
      {!hideUrlInputDisabled && (
        <StyleInput
          placeholder='Url - try this first'
          value={links[0]}
          onChange={(e) => handleAddUrl(e, 0)}
        />
      )}
      {/* Not needed so far */}
      {/* <StyleInput
        placeholder='Url (optional)'
        value={value1}
        onChange={(e) => setValue1(e.target.value)}
        onBlur={(e) => handleAddUrl(e, 1)}
      /> */}
      {!hideTextInput && (
        <StyledTextareaAutosize
          value={text}
          placeholder='Paste menu text. Useful if the happy hour menu is under a tab or loaded dynamically'
          onChange={handleTextInput}
        />
      )}
      {!hideFileUpload && (
        <Button
          component='label'
          role={undefined}
          variant='contained'
          tabIndex={-1}
          startIcon={<IoMdCloudUpload />}
          size='small'
          style={{ marginRight: 'auto', marginBottom: '10px' }}
        >
          Upload PDF
          <VisuallyHiddenInput
            type='file'
            onChange={handleFileUpload}
            multiple
          />
        </Button>
      )}
    </>
  );
};
