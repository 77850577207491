export const desktopBreakpoint = `(min-width: 650px)`;
export const olddesktopBreakpoint = `(min-width: 1000px)`;

export const fontSize = {
  h1: '28px',
  h2: '25px',
  h3: '20px',
  h4: '18px',
  textLarge: '16px',
  textMd: '15px',
  textSm: '12px',
  text: '14px',
  iconText: '13px',
  nano: '10px',
} as const;

/**
 * Font Weights
 */
export const fontWeight = {
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
} as const;

export const spacing = {
  xl: '32px',
  lg: '16px',
  md: '8px',
  sm: '4px',
  xs: '2px',
} as const;
