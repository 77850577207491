import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  desktopBreakpoint,
  fontWeight,
  spacing,
} from '../../../components/styled';
import { instagramUrl, linkedInUrl } from '../../../helpers/constants';
import { SectionWrapper } from '../../shared/components/section-wrapper';

export const FooterTwo = () => {
  return (
    <SectionWrapper
      backgroundColor={colors.slate}
      maxWidth='1800px'
      isSticky={true}
    >
      <ContentWrapper>
        <SocialsWrapper>
          <a href={instagramUrl}>
            <InstagramIcon style={{ marginRight: spacing.sm }} />
          </a>
          {/* <a href={facebookUrl}>
            <FacebookIcon style={{ marginRight: spacing.sm }} />
          </a> */}
          <a href={linkedInUrl}>
            <LinkedInIcon />
          </a>
        </SocialsWrapper>
        <Nav>
          <NavItem>
            <Link to='legal/terms'>Terms & Conditions</Link>
          </NavItem>
          <NavItem>
            <Link to='legal/privacy-policy'>Privacy Policy</Link>
          </NavItem>
          <NavItem>
            <Link to='legal/support'>Support</Link>
          </NavItem>
        </Nav>
        <CopyrightInfo>
          <span>&copy; Vibe 2024</span>
        </CopyrightInfo>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: ${spacing.lg};
  padding-bottom: ${spacing.lg};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr 1fr;

  @media ${desktopBreakpoint} {
    grid-template-rows: auto;
    grid-template-columns: 1fr 3fr 1fr;
  }
`;

const SocialsWrapper = styled.div`
  align-content: center;
  color: white;
  margin-left: auto;
  margin-right: auto;

  @media ${desktopBreakpoint} {
    margin-left: 0;
  }

  a {
    text-decoration: none;
    color: #d1d1d1;

    &:hover {
      color: white;
    }
  }
`;

const Nav = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  align-content: center;
`;

const NavItem = styled.li`
  color: white;

  text-align: center;
  padding: ${spacing.sm} 0;

  @media ${desktopBreakpoint} {
    float: left;
    text-align: start;

    & + &:before {
      content: '|';
      padding: 0 8px;
    }
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: ${fontWeight.medium};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CopyrightInfo = styled.div`
  margin-left: auto;
  color: white;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
`;
