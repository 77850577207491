import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Pub } from '../../../../../types';
import { fetchPub } from '../../../../api/fetch-pub';
import { updatePub } from '../../../../api/update-pub';
import { HappyHours } from '../../../../components/happy-hours';
import { Menu } from '../../../../components/menu';
import { spacing } from '../../../../components/styled';
import { Summary } from '../../../../components/summary';
import { SectionWrapper } from '../../../shared/components/section-wrapper';
import { useUserDetails } from '../../../shared/hooks/use-user-details';
import { EventsInputs } from '../components/events-inputs';

export const PubDetailsView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    data: { pubs, setPubs },
  } = useUserDetails();
  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);
  const [pub, setPub] = useState(
    pubs.find((pubData) => pubData.googlePlaceId === params.googlePlaceId)
  );

  useEffect(() => {
    // Should only be called for an admin loading a pub they don't own.
    const fetchPubData = async () => {
      const pubData = await fetchPub(params.googlePlaceId as string);

      if (pubData) {
        setPub(pubData);
      } else {
        setError(true);
      }
    };

    if (!pub && !error) {
      fetchPubData();
    }
  }, []);

  const saveChanges = async (newPubData: Pub) => {
    setPub(newPubData);
    await updatePub(newPubData);
    const filteredPubs = pubs.filter(
      (pubData) => pubData.googlePlaceId !== newPubData.googlePlaceId
    );
    setPubs([...filteredPubs, newPubData]);
  };

  return (
    <SectionWrapper>
      <Container>
        <Button onClick={() => navigate('/business/dashboard')}>
          Dashboard
        </Button>
        {pub && (
          <Content>
            <h3>{`${pub.name}`}</h3>
            {step == 0 && (
              <>
                <ButtonWrapper>
                  <Button onClick={() => setStep(1)}>Update Hours</Button>
                  <Button onClick={() => setStep(2)}>Update Menu</Button>
                  <Button onClick={() => setStep(3)}>
                    {pub.events && pub.events.length > 0
                      ? 'Update Events'
                      : 'Add Events'}
                  </Button>
                </ButtonWrapper>
              </>
            )}
            {step === 0 && <Summary pub={pub} />}
            {step === 1 && (
              <HappyHours
                times={pub.happyHours}
                openingHours={pub.openingHours}
                onUpdate={(newTimes) => {
                  setStep(0);
                  // setPub({ ...pub, happyHours: newTimes });
                  saveChanges({ ...pub, happyHours: newTimes });
                }}
                onCancel={() => setStep(0)}
              />
            )}
            {step === 2 && (
              <Menu
                drinks={pub.drinks}
                food={pub.food}
                onUpdate={(newMenu) => {
                  setStep(0);
                  // setPub({
                  //   ...pub,
                  //   drinks: newMenu.drinks,
                  //   food: newMenu.food,
                  // });
                  saveChanges({
                    ...pub,
                    drinks: newMenu.drinks,
                    food: newMenu.food,
                  });
                }}
                onCancel={() => setStep(0)}
              />
            )}
            {step === 3 && (
              <EventsInputs
                events={pub.events}
                onUpdate={(newEvents) => {
                  setStep(0);
                  // setPub({
                  //   ...pub,
                  //   drinks: newEvents.drinks,
                  //   food: newEvents.food,
                  // });
                  console.log('newEvents', newEvents);
                }}
              />
            )}
          </Content>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  padding-left: 50px;
`;

const Content = styled.div`
  margin: ${spacing.xl} 0;
`;

const ButtonWrapper = styled.div`
  margin-bottom: ${spacing.xl};
`;
