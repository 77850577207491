import React from 'react';
import styled from 'styled-components';
import { MenuItem } from '../../types';
import { MenuListItem } from './menu-list-item';

interface Props {
  items: MenuItem[];
  onUpdate: (newItems: MenuItem[]) => void;
}

export const MenuList = ({ items, onUpdate }: Props) => {
  const handleChange = (newValue: MenuItem, index: number) => {
    const newItems = [...items];
    newItems[index] = newValue;

    onUpdate(newItems);
  };

  const handleRemove = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    onUpdate(newItems);
  };

  return (
    <>
      {items.map((item, index) => {
        return (
          <MenuListItem
            label={item.label}
            price={item.price}
            handleChange={(newValue) => handleChange(newValue, index)}
            handleRemove={() => handleRemove(index)}
            key={`${item.label}-${item.price}-${index}`}
          />
        );
      })}
    </>
  );
};

export const HorizontalInputs = styled.div`
  display: flex;
  margin-bottom: 5px;
`;
