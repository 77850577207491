import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, Checkbox, Tooltip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useState } from 'react';
import styled from 'styled-components';
import { HappyHoursByDay, OpeningHours } from '../../types';
import {
  convertToHappyHoursByDay,
  TimeFormat,
  uniqueHappyHourTimes,
} from '../helpers/happy-hour-times';
import { colors, spacing } from './styled';
// import { CustomHeading4 } from './styled/headings';
import { useNavigate } from 'react-router-dom';
import { TimeInput } from './time-input';

interface Props {
  times: HappyHoursByDay;
  openingHours: OpeningHours;
  onUpdate: (data: HappyHoursByDay) => void;
  onCancel?: () => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledHorizontalInputs = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 0.3fr 1.5fr;
  justify-content: space-around;
  width: 100%;
  align-items: center;
`;

export const HappyHours = ({
  times,
  onUpdate,
  openingHours,
  onCancel,
}: Props) => {
  const daysNumbers = [0, 1, 2, 3, 4, 5, 6];
  const daysString = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [uniqueTimes, setUniqueTimes] = useState(
    uniqueHappyHourTimes(times, openingHours)
  );
  const navigate = useNavigate();

  const handleSave = () => {
    onUpdate(convertToHappyHoursByDay(uniqueTimes, openingHours));
    // navigate('/business/dashboard')
  };

  const handleAddTime = () => {
    const newTimeSlot: TimeFormat = {
      time: { startTime: '21:00', endTime: '22:00' },
      extra: { useOpen: false, useClose: true },
      days: [],
    };

    setUniqueTimes([...uniqueTimes, newTimeSlot]);
  };

  const handleDeleteTime = (index: number) => {
    const newTimes = uniqueTimes.filter(
      (_time, timeIndex) => timeIndex !== index
    );
    setUniqueTimes(newTimes);
  };

  const handleSetTime = (
    index: number,
    newTime: string | null,
    endTime?: boolean
  ) => {
    if (newTime) {
      if (endTime) {
        uniqueTimes[index].time.endTime = newTime;
      } else {
        uniqueTimes[index].time.startTime = newTime;
      }
    }
  };

  const toggleUseOpenCloseTime = (index: number, closeTime?: boolean) => {
    const newTimes = uniqueTimes.map((time, timeIndex) => {
      if (timeIndex === index) {
        const newExtra = closeTime
          ? { useClose: !time.extra.useClose }
          : { useOpen: !time.extra.useOpen };

        return { ...time, extra: { ...time.extra, ...newExtra } };
      }

      return time;
    });

    setUniqueTimes(newTimes);
  };

  const toggleCheckBox = (index: number, day: number) => {
    const newTimes = uniqueTimes.map((time, timeIndex) => {
      if (timeIndex === index) {
        const newDays = time.days.includes(day)
          ? time.days.filter((dayVal) => dayVal !== day)
          : [...time.days, day];

        return { ...time, days: newDays };
      }

      return time;
    });

    setUniqueTimes(newTimes);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledContainer data-testid='happy-hour-times-container'>
        <Table>
          <Row>
            <TimeCell>
              <span>Time</span>
            </TimeCell>
            {daysString.map((day, index) => {
              return (
                <Cell key={day}>
                  <span>{day}</span>
                  {/* <span>{timesString(index)}</span> */}
                </Cell>
              );
            })}
            <DeleteCell />
          </Row>
          {uniqueTimes.map(({ time, days, extra }, index) => {
            return (
              <Row key={`${time.startTime}-${time.endTime}-${index}`}>
                <TimeCell>
                  <StyledHorizontalInputs>
                    <>
                      {extra.useOpen ? (
                        <div>
                          <span>Open</span>
                          <Tooltip title='Set Time'>
                            <Button
                              onClick={() => toggleUseOpenCloseTime(index)}
                            >
                              <AccessTimeIcon htmlColor={colors.blue} />
                            </Button>
                          </Tooltip>
                        </div>
                      ) : (
                        <>
                          <TimeInput
                            startTime={true}
                            value={time.startTime}
                            onTimeChange={(newTime) =>
                              handleSetTime(index, newTime)
                            }
                            customText='From Open'
                            handleCustomTextClick={() =>
                              toggleUseOpenCloseTime(index)
                            }
                          />
                        </>
                      )}
                    </>
                    <span>-</span>
                    <>
                      {extra.useClose ? (
                        <div>
                          <span>Close</span>
                          <Tooltip title='Set Time'>
                            <Button
                              onClick={() =>
                                toggleUseOpenCloseTime(index, true)
                              }
                            >
                              <AccessTimeIcon htmlColor={colors.blue} />
                            </Button>
                          </Tooltip>
                        </div>
                      ) : (
                        <>
                          <TimeInput
                            startTime={false}
                            value={time.endTime}
                            onTimeChange={(newTime) =>
                              handleSetTime(index, newTime, true)
                            }
                            customText='Till Close'
                            handleCustomTextClick={() =>
                              toggleUseOpenCloseTime(index, true)
                            }
                          />
                        </>
                      )}
                    </>
                  </StyledHorizontalInputs>
                </TimeCell>
                {daysNumbers.map((day) => {
                  return (
                    <Cell key={day}>
                      <Checkbox
                        checked={days.includes(day)}
                        onClick={() => toggleCheckBox(index, day)}
                      />
                    </Cell>
                  );
                })}
                <DeleteCell>
                  <StyledButton onClick={() => handleDeleteTime(index)}>
                    X
                  </StyledButton>
                </DeleteCell>
              </Row>
            );
          })}
          <Row>
            <TimeCell>
              <StyledButton onClick={handleAddTime}>Add</StyledButton>
            </TimeCell>
          </Row>
        </Table>
      </StyledContainer>
      <HorizontalWrapper>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
        <Button onClick={handleSave}>Save</Button>
      </HorizontalWrapper>
    </LocalizationProvider>
  );
};

const Table = styled.div`
  width: 100%;
  border: 2px ${colors.paleGrey} solid;
`;

const Row = styled.div`
  display: flex;
  border-top: 2px ${colors.paleGrey} solid;
  align-items: center;
  text-align: center;
`;

const Cell = styled.div`
  padding: ${spacing.md};
  flex-basis: 6.5%;
`;

const TimeCell = styled(Cell)`
  flex-basis: 35%;
  border-right: 2px ${colors.paleGrey} solid;
`;

const DeleteCell = styled(Cell)`
  flex-basis: 4%;
`;

const StyledButton = styled(Button)`
  width: 100%;
  min-width: 20px !important;
`;

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
