import { User } from '../../types';
import { baseUrl } from './base-url';

export const fetchUsers = async () => {
  try {
    const token = localStorage.getItem('token');
    const result = await fetch(`${baseUrl}/api/users`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const pubs: User[] = await result.json();

    return pubs;
  } catch (err) {
    console.log('Failed to fetch users.', err);
    throw err;
  }
};
