import React, { useState } from 'react';
// import TimePicker from 'react-time-picker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import styled from 'styled-components';
import { CustomPopper } from './custom-popper';
import { spacing } from './styled';

const StyledContainer = styled.div`
  margin-top: ${spacing.md};
`;

interface TimeProps {
  startTime: boolean;
  value: string;
  onTimeChange: (time: string | null) => void;
  customText?: string;
  handleCustomTextClick: () => void;
}

export const TimeInput = ({
  startTime,
  value,
  onTimeChange,
  customText,
  handleCustomTextClick,
}: TimeProps) => {
  const label = startTime ? 'Start Time' : 'End Time';
  const [tempValue, setTempValue] = useState<Dayjs | null>(
    dayjs(`2018-04-13 ${value}`)
  );
  const [open, setOpen] = useState(false);

  const handleAccept = (value: dayjs.Dayjs | null) => {
    const mins = value?.minute();
    const minsString = mins === 0 ? '00' : mins;
    onTimeChange(`${value?.hour()}:${minsString}`);
  };

  const handleChange = (newValue: dayjs.Dayjs | null) => {
    setTempValue(newValue);
  };

  return (
    <StyledContainer>
      <StyledTimePicker
        open={open}
        onClose={() => setOpen(false)}
        label={label}
        minutesStep={30}
        skipDisabled={true}
        value={tempValue}
        onChange={handleChange}
        onAccept={handleAccept}
        slotProps={{
          textField: { size: 'small', onClick: () => setOpen(!open) },
          inputAdornment: { onClick: () => setOpen(!open) },
        }}
        slots={{
          popper: (popperProps) => (
            <CustomPopper
              {...popperProps}
              text={customText}
              handleCustomTextClick={handleCustomTextClick}
            />
          ),
        }}
      />
    </StyledContainer>
  );
};

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  max-width: 140px;
`;
