import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Pub } from '../../../../types';
import {
  ButtonCell,
  Cell,
  HeadingRow,
  Row,
  StyledTable,
} from '../../admin/dashboard/components/styled';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { useUserDetails } from '../../shared/hooks/use-user-details';
import { UpdatePassword } from './components/update-password';

const dummyPubs: Pub[] = [
  {
    googlePlaceId: '1',
    name: `Smith's`,
    website: 'https://www.smithsofgastown.ca/',
    latitude: 10,
    longitude: 20,
    happyHours: {
      0: [],
      1: [{ startTime: '15:00', endTime: '18:00' }],
      2: [{ startTime: '15:00', endTime: '18:00' }],
      3: [{ startTime: '12:00', endTime: '24:00' }],
      4: [{ startTime: '15:00', endTime: '18:00' }],
      5: [{ startTime: '15:00', endTime: '18:00' }],
      6: [],
    },
    drinks: [
      {
        label: 'Highball',
        price: '2.00',
      },
      {
        label: 'Jameson',
        price: '4.00',
      },
    ],
    food: [
      {
        label: 'Fries',
        price: '2.00',
      },
      {
        label: 'Burger and a beer',
        price: '4.00',
      },
    ],
    links: [],
    openingHours: [],
    vicinity: '208 &, 212 Carrall Street, Vancouver',
  },
];

export const BusinessDashboard = () => {
  const navigate = useNavigate();
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const {
    data: { role, pubs },
    clearUserData,
  } = useUserDetails();

  const handleLogout = () => {
    clearUserData();
    localStorage.removeItem('token');
    navigate('/business/login');
  };

  const handleAdminClick = () => {
    navigate('/admin/dashboard');
  };

  return (
    <SectionWrapper>
      <Container>
        <h2>Your Listings</h2>
        <StyledTable>
          <HeadingRow>
            <HeadingCell>
              <span>Name</span>
            </HeadingCell>
            <HeadingCell>
              <span>Address</span>
            </HeadingCell>
            <ButtonCell></ButtonCell>
            <ButtonCell></ButtonCell>
          </HeadingRow>
          {pubs &&
            pubs.map((pub) => {
              return (
                <Row
                  key={pub.googlePlaceId}
                  onClick={() =>
                    navigate(`/business/dashboard/pub/${pub.googlePlaceId}`)
                  }
                >
                  <Cell>
                    <span>{pub.name}</span>
                  </Cell>
                  <Cell>
                    <span>{pub.vicinity}</span>
                  </Cell>
                  <ButtonCell
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/business/dashboard/pub/${pub.googlePlaceId}`);
                    }}
                  >
                    <Button>Update</Button>
                  </ButtonCell>
                  <ButtonCell>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      Delete
                    </Button>
                  </ButtonCell>
                </Row>
              );
            })}
        </StyledTable>

        <Button onClick={handleLogout}>Logout</Button>
        <Button onClick={() => setShowUpdatePassword(true)}>
          Update Password
        </Button>
        {showUpdatePassword && (
          <UpdatePassword setShowUpdatePassword={setShowUpdatePassword} />
        )}
        {role === 'admin' && <Button onClick={handleAdminClick}>Admin</Button>}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  width: 100%;
  padding-left: 50px;
`;

// const StyledTable = styled.div`
//   border: 2px ${colors.paleGrey} solid;
// `;

// const Row = styled.div`
//   display: flex;
//   border-top: 1px ${colors.paleGrey} solid;
//   align-items: center;
// `;

// const Cell = styled.div`
//   padding: ${spacing.md};
//   flex-basis: 30%;
// `;

const HeadingCell = styled(Cell)`
  font-weight: 600;
`;

// const CheckboxCell = styled(Cell)`
//   flex-basis: 3%;
//   text-align: center;
// `;

// const ButtonCell = styled(Cell)`
//   flex-basis: 20%;
//   text-align: center;
// `;
