import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import { turnstileSiteKey } from '../../../helpers/constants';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { useBusinessLogin } from './hooks/use-business-login';

export const BusinessLogin = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const { error, login } = useBusinessLogin();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const loginUser = async () => {
      login({ email, password }, turnstileToken);
    };

    loginUser();
  };

  return (
    <SectionWrapper>
      <Container>
        <FormWrapper>
          <StyledForm onSubmit={handleSubmit}>
            <StyledLabel htmlFor='login-email'>Email</StyledLabel>
            <StyleInput
              id='login-email'
              style={{ width: '100%' }}
              placeholder='Email'
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <StyledLabel htmlFor='login-password'>Password</StyledLabel>
            <StyleInput
              id='login-password'
              style={{ width: '100%' }}
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type='password'
            />
            <Turnstile
              sitekey={turnstileSiteKey}
              onVerify={(token) => setTurnstileToken(token)}
            />
            <Button type='submit' disabled={email === '' || password === ''}>
              Login
            </Button>
          </StyledForm>
          {error && (
            <span style={{ color: 'red' }}>There was an error logging in.</span>
          )}
        </FormWrapper>
        <Link to='/business/register'>Register</Link>
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  color: ${colors.blue};
  text-align: center;
  max-width: 600px;
`;

const StyledLabel = styled.label``;

const StyleInput = styled.input`
  width: 100%;
  margin-bottom: ${spacing.lg};
  margin-top: ${spacing.sm};
  padding: ${spacing.md};
  border: none;
  background-color: white;
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.slate};
`;

const FormWrapper = styled.div`
  background-color: ${colors.paleGrey};
  padding: ${spacing.lg};
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.lg};
  border-radius: 10px;
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.lg};
`;

const StyledForm = styled.form`
  width: 100%;
  color: ${colors.slate};
  text-align: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;
