import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Pub, User } from '../../../../../types';
import { NewPubForm } from '../../../../components/new-pub-form';
import { PubRow } from './pub-row';
import { ButtonCell, Cell, HeadingRow, StyledTable } from './styled';

interface Props {
  pubs: Pub[];
  setPubs: (pubs: Pub[]) => void;
  users: User[];
}

export const PubsTab = ({ pubs, setPubs, users }: Props) => {
  const [showAddForm, setShowAddForm] = useState(false);

  return (
    <>
      <StyledTable>
        <HeadingRow>
          <Cell>
            <span>Name</span>
          </Cell>
          <Cell>
            <span>Address</span>
          </Cell>
          <Cell>Owner</Cell>
          <ButtonCell></ButtonCell>
        </HeadingRow>
        {pubs.map((pub) => (
          <PubRow
            key={pub.googlePlaceId}
            pub={pub}
            setPubs={setPubs}
            users={users}
          />
        ))}
      </StyledTable>
      {showAddForm ? (
        <NewPubForm />
      ) : (
        <Button onClick={() => setShowAddForm(true)}>Add a Business</Button>
      )}
    </>
  );
};
