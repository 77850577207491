import React from 'react';
import { colors } from '../../../components/styled';

export const PreSubmissionMessage = () => (
  <>
    <h1 id='suggest'>Help Grow Vibe!</h1>
    <p style={{ color: colors.slate }}>
      Whether it's a cozy corner pub or a trendy rooftop bar with an unbeatable
      happy hour or exciting live events, we’d love to add it to our list!
    </p>
  </>
);
