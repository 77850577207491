import { Button } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors, spacing } from '../../../components/styled';
import { SectionWrapper } from '../../shared/components/section-wrapper';
import { PreSubmissionMessage } from './pre-submission-message';
import { SuccessMessage } from './success-message';
import { SuggestionForm } from './suggestion-form';

export const Suggestion = () => {
  const [submitted, setSubmitted] = useState(false);

  return (
    <SectionWrapper>
      <Container>
        {submitted ? (
          <>
            <SuccessMessage />
            <Button onClick={() => setSubmitted(false)}>Submit Another</Button>
          </>
        ) : (
          <>
            <PreSubmissionMessage />
            <FormWrapper>
              <SuggestionForm setSubmitted={setSubmitted} />
            </FormWrapper>
          </>
        )}
      </Container>
    </SectionWrapper>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.lg};
  margin-bottom: ${spacing.lg};
  color: ${colors.blue};
  text-align: center;
  max-width: 600px;
`;

const FormWrapper = styled.div`
  background-color: ${colors.paleGrey};
  padding: ${spacing.lg};
  padding-top: ${spacing.md};
  padding-bottom: ${spacing.lg};
  border-radius: 10px;
  margin-top: ${spacing.xl};
  margin-bottom: ${spacing.lg};
`;
