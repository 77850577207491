import { jwtDecode } from 'jwt-decode';
import { UserRole } from '../../../../types';

export const useUserRole = (): UserRole => {
  const token = localStorage.getItem('token');

  try {
    if (token) {
      const decodedToken: { user: { role: string } } = jwtDecode(token);

      return decodedToken?.user.role as UserRole;
    }

    return 'client';
  } catch (err) {
    console.error('Failed to decode token', err);
    return 'client';
  }
};
