import React from 'react';
import { Link } from 'react-router-dom';
import { SectionWrapper } from '../shared/components/section-wrapper';
import { LegalTextContainer } from '../shared/components/styled';

export const Legal = () => {
  return (
    <SectionWrapper>
      <LegalTextContainer>
        <h1>Legal</h1>
        <ul>
          <li>
            <Link to='privacy-policy'>Privacy Policy</Link>
          </li>
          <li>
            <Link to='terms'>Terms & Conditions</Link>
          </li>
          <li>
            <Link to='support'>Support</Link>
          </li>
        </ul>
      </LegalTextContainer>
    </SectionWrapper>
  );
};
