import { baseUrl } from '../../../api/base-url';

export const deletePub = async (pubIds: string[]) => {
  try {
    const token = localStorage.getItem('token');
    return await fetch(`${baseUrl}/api/pubs`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ pubIds }),
    });
  } catch (err) {
    console.log('Failed to delete pub.', err);
  }
};
