import React from 'react';
import { Benefits } from './components/benefits';
import { Cover } from './components/cover';
import { Suggestion } from './components/suggestion';

export const Home = () => {
  return (
    <>
      <Cover />
      <Benefits />
      {/* <ImageCarousel /> */}
      <Suggestion />
    </>
  );
};
